<template>
  <div :class="[borderhover ? 'draggle-area-active' : '']" id="draggle-area">
    <vxe-toolbar class="" custom>
      <template v-slot:buttons>
        <div class="d-flex align-items-center">
          <vxe-button
            size="medium"
            content="上传文件"
            class="mx-1"
            transfer
            status="my-primary"
          >
            <template #dropdowns style="z-index: 1000">
              <vxe-button
                content="文件"
                @click="selectFiles(false)"
              ></vxe-button>
              <vxe-button
                content="文件夹"
                @click="selectFiles(true)"
              ></vxe-button>
            </template>
          </vxe-button>

          <vxe-button
            status="my-primary"
            @click="multiDisplay = !multiDisplay"
            size="medium"
            :content="multiDisplay ? '关闭批量' : '批量操作'"
            class="mx-1"
          ></vxe-button>

          <vxe-button
            size="medium"
            content="批量分组"
            v-if="
              multiDisplay && $commonHelpers.getPermissions('tagStore.editTag')
            "
            class="mx-1"
            transfer
            status="my-primary"
          >
            <template #dropdowns style="z-index: 1000">
              <vxe-button
                v-for="item in tagArr"
                :content="item"
                @click="multiEditFileTag(item)"
              ></vxe-button>
            </template>
          </vxe-button>

          <vxe-input
            v-model="filterName"
            type="search"
            placeholder="搜文件"
            class="mx-1"
            size="medium"
            style="width: 100px"
          ></vxe-input>

          <vxe-select
            v-model="currKey"
            placeholder="分组选择"
            size="medium"
            style="width: 100px"
          >
            <vxe-option :value="-1" label="全部"></vxe-option>
            <vxe-option
              v-for="(item, index) in tagArr"
              :key="index"
              :value="index"
              :label="item"
            ></vxe-option>
          </vxe-select>
        </div>
      </template>
    </vxe-toolbar>
    <vxe-table
      :data="list"
      :loading="bindFileTableLoading"
      border
      resizable
      show-overflow
      show-header-overflow
      highlight-hover-row
      ref="fileTable"
      :column-config="{ resizable: true }"
      :height="fileTableHeight"
      size="mini"
      :stripe="true"
      :sort-config="{ trigger: 'cell', multiple: true }"
      :scroll-x="{ gt: 30 }"
      :scroll-y="{ mode: 'wheel', gt: 100 }"
      :empty-render="{ name: 'NotData' }"
      :filter-config="{ showIcon: false }"
      :checkbox-config="{ trigger: 'cell', range: true }"
      align="center"
      :edit-config="{ trigger: 'click', mode: 'cell', icon: ' ' }"
    >
      <vxe-column
        field="multi"
        type="checkbox"
        title="批量"
        width="60"
        v-if="multiDisplay"
      >
      </vxe-column>

      <vxe-column
        min-width="300"
        field="name"
        title="文件名"
        :edit-render="{ name: 'input' }"
      >
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.name }}
          </div>
        </template>
        <template #edit="{ row }">
          <vxe-input
            :max="100"
            :min="0"
            v-model="row.name"
            placeholder="文件名"
            @change="fileNameChange = true"
            @blur="editFileName(row)"
          ></vxe-input>
        </template>
      </vxe-column>

      <vxe-column width="60" field="user_name" title="上传者">
        <template #default="{ row }">
          <div class="d-flex justify-start">
            <div
              style="
                word-break: keep-all;
                white-space: pre-line;
                text-align: start;
              "
            >
              {{ row.user_name }}
            </div>
          </div>
        </template>
      </vxe-column>

      <vxe-column
        width="80"
        field="tags"
        title="分组"
        :filters="[{ data: '' }]"
        :filter-method="
          (value) => $tableHelpers.filterInputMethod(value, 'tags')
        "
      >
        <template #default="{ row }">
          <select
            required
            v-model="row.tags"
            style="width: 100%"
            @change="editFileTag(row)"
            :style="{ color: row.tags ? '#000000' : '#cccccc' }"
          >
            <option disabled selected value style="display: none">
              点选分组
            </option>
            <option
              class="myOption"
              v-for="(item, index) in tagArr"
              :key="index"
            >
              {{ item }}
            </option>
          </select>
        </template>
        <template #filter="{ $panel, column }">
          <template v-for="(option, index) in column.filters">
            <input
              class="pa-2"
              type="type"
              :key="index"
              v-model="option.data"
              @input="$panel.changeOption($event, !!option.data, option)"
              @keyup.enter="$panel.confirmFilter()"
              placeholder="按回车确认筛选"
            />
          </template>
        </template>
      </vxe-column>
      <vxe-column width="80" field="created_at" title="上传时间" sortable>
        <template #default="{ row }">
          {{ row.created_at.split(" ")[0] }}
        </template>
      </vxe-column>
      <vxe-column
        title="操作"
        type="seq"
        width="95"
        :resizable="false"
        show-overflow
      >
        <template #default="{ row }">
          <a
            href="javascript:;"
            @click="$commonHelpers.previewFile(row.id)"
            class="text-xs font-weight-normal text-typo mr-1"
            >预览</a
          >
          <a
            href="javascript:;"
            @click="$commonHelpers.downloadFile(row.id)"
            class="text-xs font-weight-normal text-typo mr-1"
            >下载</a
          >
          <a
            href="javascript:;"
            @click="deleteFile(row)"
            class="text-xs font-weight-normal text-typo"
            v-if="$commonHelpers.getPermissions('file.crud')"
            >删除</a
          >
        </template>
      </vxe-column>
    </vxe-table>

    <div style="display: none">
      <v-file-input
        id="bindFileSelect"
        v-model="files"
        multiple
        :webkitdirectory="uploadFolder"
      ></v-file-input>
    </div>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import File from "@/api/file.js";
import Stock from "@/api/stock.js";
import XEUtils from "xe-utils";
export default {
  name: "BindFile",
  components: {},
  props: {
    stockId: "",
    currTag: "",
    fileTableHeight: "",
  },

  data() {
    return {
      borderhover: false,

      currKey: -1,
      tagArr: [
        "数据库",
        "公告",
        "研报",
        "点评",
        "会议纪要",
        "调研纪要",
        "入池研报",
        "内部研报",
        "其他",
      ],

      tableData: [],
      filterName: "",

      bindFileTableLoading: false,
      files: [],

      multiDisplay: false,
      fileNameChange: false,

      uploadFolder: "webkitdirectory",
    };
  },
  computed: {
    list() {
      const filterName = XEUtils.toValueString(this.filterName)
        .trim()
        .toLowerCase();
      if (filterName) {
        const filterRE = new RegExp(filterName, "gi");
        const searchProps = ["name", "user_name", "tags"];
        const rest = this.tableData.filter((item) =>
          searchProps.some(
            (key) =>
              XEUtils.toValueString(item[key])
                .toLowerCase()
                .indexOf(filterName) > -1
          )
        );
        return rest;
      }
      return this.tableData;
    },
  },
  beforeDestroy() {
    let dropbox = document.getElementById("draggle-area");
    dropbox.removeEventListener("drop", this.enentDrop, false);
    dropbox.removeEventListener("dragleave", this.dragleave, false);
    dropbox.removeEventListener("dragenter", this.dragenter, false);
    dropbox.removeEventListener("dragover", this.dragover, false);
  },

  mounted() {
    if (this.fileTableHeight == "") {
      this.fileTableHeight = document.body.offsetHeight - 740 + "px";
    }

    if (this.stockId) {
      this.getFileList();
      Bus.$on("refreshStockBindFile", (e) => {
        this.getFileList();
      });
    }

    let dropbox = document.getElementById("draggle-area");
    dropbox.addEventListener("drop", this.enentDrop, false);
    dropbox.addEventListener("dragleave", this.dragleave, false);
    dropbox.addEventListener("dragenter", this.dragenter, false);
    dropbox.addEventListener("dragover", this.dragover, false);

    if (this.currTag) {
      let data = JSON.parse(JSON.stringify(this.currTag.file));
      data.forEach((item, index) => {
        data[index].tags = item.tags.toString();
      });
      this.tableData = data;
    }
  },
  methods: {
    getFileList() {
      this.bindFileTableLoading = true;
      Stock.getFileList(this.stockId)
        .then((res) => {
          res.data.forEach((item, index) => {
            res.data[index].tags = item.tags.toString();
          });
          this.tableData = res.data;
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.bindFileTableLoading = false;
        });
    },

    selectFiles(isFolder) {
      if (isFolder) {
        this.uploadFolder = "webkitdirectory";
      } else {
        this.uploadFolder = false;
      }
      setTimeout(() => {
        document.getElementById("bindFileSelect").click();
      }, 500);
    },

    upload(file) {
      //检查重复
      let haveSameName = false;
      let fileFilter = [];
      file.forEach((selectItem) => {
        let have = false;
        this.tableData.forEach((oldItem) => {
          if (oldItem.name == selectItem.name) {
            haveSameName = true;
            have = true;
          }
        });
        if (!have) {
          fileFilter.push(selectItem);
        }
      });
      if (haveSameName) {
        this.showToast("您选择的文件中有重复文件，已帮您跳过", "warning", 5000);
      }
      //过滤重名后如果有文件才上传
      if (fileFilter.length > 0) {
        if (this.stockId) {
          Bus.$emit("addFile", {
            file: fileFilter,
            type: "stockFile",
            info: { stockId: this.stockId },
          });
        } else if (this.currTag) {
          Bus.$emit("addFile", {
            file: fileFilter,
            type: "tagFile",
            info: {
              tagType: "race",
              tagId: this.currTag.id,
            },
          });
        }
      }
    },

    deleteFile(row) {
      this.$layer.confirm("确定要删除这个文件吗？", (layerid) => {
        this.showLoading("删除中，请稍候");
        File.deleteFile(row.id)
          .then((res) => {
            this.showToast("删除成功", "success", 5000);
            Bus.$emit("refreshStockBindFile", {});
            Bus.$emit("refreshTagDetail", true);
          })
          .catch((err) => {
            console.log(err.msg);
            this.showToast(err.msg, "danger", 10000);
          })
          .finally(() => {
            this.hideLoading();
          });
        this.$layer.close(layerid);
      });
    },

    editFileTag(row) {
      //console.log(row);return;
      File.editFile(row)
        .then((res) => {
          this.showToast("修改成功", "success", 2000);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {});
    },
    multiEditFileTag(tag) {
      //console.log(row);return;
      let selectRecords = this.$refs.fileTable.getCheckboxRecords();
      if (selectRecords.length < 1) {
        this.showToast("请先选择项目", "warning", 5000);
        return;
      }
      let arr = [];
      selectRecords.forEach((i) => {
        arr.push(i.id);
      });
      this.showLoading("修改中，请稍候");
      File.editFilesTags(arr, [tag])
        .then((res) => {
          this.showToast("修改成功", "success", 2000);
          Bus.$emit("refreshStockBindFile", {});
          Bus.$emit("refreshTagDetail", true);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    editFileName(row) {
      if (this.fileNameChange) {
        this.fileNameChange = false;
        this.showLoading("修改文件名中，请稍候");
        File.editFile(row)
          .then((res) => {
            this.showToast("修改成功", "success", 2000);
          })
          .catch((err) => {
            console.log(err.msg);
            this.showToast(err.msg, "danger", 10000);
          })
          .finally(() => {
            this.hideLoading();
          });
      }
    },

    dragenter(e) {
      this.borderhover = true;
      this.preventDefault(e);
    },
    dragleave(e) {
      this.borderhover = false;
      this.preventDefault(e);
    },
    dragover(e) {
      this.borderhover = true;
      this.preventDefault(e);
    },
    enentDrop(e) {
      this.borderhover = false;

      let fileData = e.dataTransfer.files;
      //console.log(fileData);
      let fileArr = [];
      fileData.forEach((item) => {
        if (item.type) {
          fileArr.push(item);
        }
      });
      this.files = fileArr;
      this.preventDefault(e);
    },
    preventDefault(e) {
      e.stopPropagation();
      e.preventDefault(); //必填字段
    },
  },
  watch: {
    files(value) {
      if (value) {
        this.upload(value);
      }
      this.uploadFolder = "webkitdirectory";
    },
    currKey(val) {
      if (val == -1) {
        this.$tableHelpers.filterInput(this.$refs.fileTable, "tags", "");
      } else {
        this.$tableHelpers.filterInput(
          this.$refs.fileTable,
          "tags",
          this.tagArr[val]
        );
      }
    },
    currTag() {
      let data = JSON.parse(JSON.stringify(this.currTag.file));
      data.forEach((item, index) => {
        data[index].tags = item.tags.toString();
      });
      this.tableData = data;
    },
  },
};
</script>
<style scoped="scoped">
.myOption {
  color: #000000;
}

.draggle-area-active {
  border: 1px solid #bababa;
}
</style>
